import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState, getCurrentParkId, formatDate } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

// import { IS_GET_ORDER_ON_HEADER } from "@reco-w/common-common";
import { Namespaces as commonNamespaces } from "@reco-w/common-models-re";
import { activityService } from "@reco-w/activity-service";
import { articleService } from "@reco-w/article-service";
import { policyImplementationService, policyService } from "@reco-w/policy-service";
// import { ArticleCatalogueEnum } from "@reco-w/article-models";
import { marketService } from "@reco-w/market-service";
import { INSTITUTION_SERVICE_TYPE_TAG_CODE } from "@reco-w/market-models";

import { ContactTypeEnum, FORUM_DIRE_TAG_CODE, FORUM_TYPE_TAG_CODE, HUOBLX_TAG_CODE, Namespaces, OrganizationTypeEnum, PictureTypeEnum } from "./common";
import {
    memberCircleService,
    r2323ActivityApplyService,
    r2323ActivityContactService,
    r2323ActivityContributorService,
    r2323ActivityPartnerService,
    r2323ActivityPictureService,
    r2323ActivityService,
    talksService,
} from "@reco-w/bi-home-service";
import moment from "moment";
import { Namespaces as menuNamespaces } from "@reco-w/secondpage-models";
import { OTime } from "@reco/util-func";

export namespace homeModel {
    export const namespace = Namespaces.home;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
        languageType: client["languageType"],
    });

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: `${menuNamespaces.pageMenu}/update`, data: { openKey: null } });
                // yield put({ type: "getTags" });
            } catch (error) {
                yield call(message!.error, "initPage：" + error.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取首页数据
         */
        *getHomeData({ message, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                yield put({ type: "getOrganization", data });
                yield put({ type: "getGroups", data });
                yield put({ type: "getContributors", data });
                yield put({ type: "getPartners", data: { activityId: data.activityId } });
                yield put({ type: "getPictures", data: { activityId: data.activityId, customType: PictureTypeEnum.banner }, map: "bannerPics" });
                yield put({ type: "getPictures", data: { activityId: data.activityId, customType: PictureTypeEnum.jtlx }, map: "jtPics" });
                yield put({ type: "getPictures", data: { activityId: data.activityId, customType: PictureTypeEnum.hcbj }, map: "hcPics" });
                yield put({ type: "getPictures", data: { activityId: data.activityId, customType: PictureTypeEnum.jdtj }, map: "jdPics" });
                yield put({ type: "getContacts", data });
                // yield put({ type: "getDateRange", data });
                yield put({ type: "getActivityTypeRange" });
                yield put({ type: "getActivityList", data });
                yield put({ type: "getPreviousList", data });
                yield put({ type: "getCircle" });
                yield put({ type: "getNegotiate", data: { activityId: data.activityId } });
            } catch (e) {
                yield call(message!.error, "getHomeData：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取服务类型
         */
        *getServiceCatalogue({ message }, { call, put }) {
            try {
                const catalogues = yield call(tagService.getTagByTagClass, {
                    tagClass: INSTITUTION_SERVICE_TYPE_TAG_CODE,
                    parkId: getCurrentParkId(),
                });
                catalogues && catalogues.pop();
                yield put({ type: "input", data: { catalogues } });
            } catch (e) {
                yield call(message!.error, "getServiceCatalogue：" + e.errmsg);
            }
        },

        /**
         * 获取资讯列表
         */
        *getArticleList({ message, data, catalogueCode, map }, { call, put }) {
            try {
                const result = yield call(articleService.getPaged, {
                    pageIndex: 1,
                    pageSize: 2,
                    isPublish: true,
                    catalogueCode,
                    orderBy: "isTop desc,sequence asc,publishTime desc",
                    parkId: getCurrentParkId(),
                    ...data,
                });

                yield put({ type: "input", data: { [map]: result.items } });
            } catch (e) {
                yield call(message!.error, "getArticleList：" + e.errmsg);
            }
        },

        /**
         * 获取政策列表
         */
        *getPolicyList({ message, data }, { call, put, select }) {
            try {
                const config = yield call(policyService.getConfig);
                const unitConfig = config.unitConfig;
                const declareMode = unitConfig?.declareMode;

                const state = yield select((state) => state[Namespaces.home]),
                    certifyDetail = state!.certifyDetail;

                const result = yield call(policyImplementationService.getDeclareImplementationDetailList, {
                    customerId: certifyDetail?.companyId,
                    isValid: true,
                    isPublish: true,
                    detailIsAllowDeclare: true,
                    parkId: getCurrentParkId(),
                    pageSize: 4,
                    ...data,
                });

                yield put({ type: "input", data: { policies: result.items, declareMode } });
            } catch (e) {
                yield call(message!.error, "getPolicyList：" + e.errmsg);
            }
        },

        /**
         * 获取认证身份
         */
        *getCertify({ message, isgetOrder, isRefreshUser }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.configCommon}/getMemberConfig` });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, isRefreshUser, isGetInstitution: isgetOrder, isInstitutionRefresh: true });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState.user,
                    certifyDetail = memberCommonState.certifyDetail;
                const configCommonState: any = yield select((state) => state[commonNamespaces.configCommon]);
                const isOnlyAllowOneMember = configCommonState.isOnlyAllowOneMember;

                yield put({ type: "input", data: { certifyDetail, user, isOnlyAllowOneMember } });

                if (isgetOrder) {
                    const workorderCommonState: any = yield select((state) => state[commonNamespaces.workorderCommon]);
                    const institution = workorderCommonState.institution;
                    yield put({ type: "input", data: { institution } });
                    yield put({ type: "getAcceptanceMode", message });
                    yield put({ type: "getCheckInDetail", userId: user && user.currentUser && user.currentUser.id, message });
                }
            } catch (e) {
                yield call(message!.error, "getCertify" + e.errmsg);
            }
        },

        /**
         * 获取受理模式
         */
        *getAcceptanceMode({ message }, { call, put }) {
            try {
                const institutionMode = yield call(marketService.getAcceptanceMode);

                yield put({ type: "input", data: { institutionMode } });
            } catch (e) {
                yield call(message!.error, "getAcceptanceMode：" + e.errmsg);
            }
        },

        /**
         * 获取 入驻申请  工单
         * @param { data, message, userId }
         * @param { call, put, select }
         */
        *getCheckInDetail({ data, message, userId }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.workorderCommon}/getCheckInDetail`, data, userId });

                const workorderCommonState = yield select((state) => state[commonNamespaces.workorderCommon]),
                    checkInData = workorderCommonState.checkInData;

                const checkOrderId = checkInData?.isCheckCancel ? checkInData && checkInData?.checkOrderId : null,
                    checkStateId = checkInData?.isCheckCancel ? checkInData && checkInData?.checkStateId : null;
                yield put({ type: "input", data: { checkInData, checkOrderId, checkStateId } });
            } catch (e) {
                yield call(message?.error, "getCheckInDetail" + e.errmsg);
            }
        },

        // 获取峰会信息
        *getSingle({ message, callback }, { call, put }) {
            try {
                const result = yield call(r2323ActivityService.getSingle, { customType: 0, languageType: state.languageType, isValid: true });

                yield put({
                    type: "input",
                    data: {
                        single: result && result.activityVM,
                        activityId: result && result.activityVM?.id,
                        r2323Year: result && result.activityVM?.r2323Year,
                    },
                });
                if (callback) yield call(callback, result && result.activityVM?.id);
            } catch (e) {
                yield call(message!.error, "getSingle" + e.errmsg);
            }
        },

        // 获取峰会组织架构
        *getOrganization({ message, data }, { call, put }) {
            try {
                let result = yield call(r2323ActivityService.getOrganization, { activityId: data.activityId });
                if (result && result.length > 0) {
                    const zddwDatas = result.filter((f) => f.customType === OrganizationTypeEnum.zddw);
                    const zcdwDatas = result.filter((f) => f.customType === OrganizationTypeEnum.zcdw);
                    const zbdwDatas = result.filter((f) => f.customType === OrganizationTypeEnum.zbdw);
                    const cbdwDatas = result.filter((f) => f.customType === OrganizationTypeEnum.cbdw);
                    yield put({ type: "input", data: { zddwDatas, zcdwDatas, zbdwDatas, cbdwDatas } });
                }
                yield put({ type: "input", data: { organization: result } });
            } catch (e) {
                yield call(message!.error, "getOrganization" + e.errmsg);
            }
        },

        // 获取峰会议程
        *getGroups({ message, data }, { call, put }) {
            try {
                const result = yield call(r2323ActivityService.getGroups, data.activityId);
                yield put({ type: "input", data: { groups: result } });
            } catch (e) {
                yield call(message!.error, "getGroups" + e.errmsg);
            }
        },

        // 峰会嘉宾
        *getContributors({ message, data }, { call, put }) {
            try {
                const result = yield call(r2323ActivityContributorService.getList, { activityId: data.activityId, orderBy: "sequence desc,inputTime desc" });
                yield put({ type: "input", data: { contributors: result?.filter((_f, i) => i < 36) } });
            } catch (e) {
                yield call(message!.error, "getContributors" + e.errmsg);
            }
        },

        // 峰会合作伙伴
        *getPartners({ message, data }, { call, put }) {
            try {
                const tags = yield call(tagService.getTagByTagClass, {
                    tagClass: HUOBLX_TAG_CODE,
                    parkId: getCurrentParkId(),
                });
                let fhPartners: any = [];
                if (tags) {
                    for (let i = 0; i < tags.length; i++) {
                        const result = yield call(r2323ActivityPartnerService.getList, {
                            customType: tags[i]?.tagValue,
                            ...data,
                        });

                        fhPartners.push({
                            tag: tags[i],
                            items: result,
                        });
                    }
                }

                yield put({ type: "input", data: { fhPartners: fhPartners } });
            } catch (e) {
                yield call(message!.error, "getPartners" + e.errmsg);
            }
        },

        // 峰会指南/Banner图
        *getPictures({ message, data, map }, { call, put }) {
            try {
                const result = yield call(r2323ActivityPictureService.getList, { isFromApp: true, attachType: 1, ...data });
                yield put({ type: "input", data: { [map]: result?.filter(f => f.pictureUrl) } });
            } catch (e) {
                yield call(message!.error, "getPictures" + e.errmsg);
            }
        },

        // 峰会联系方式
        *getContacts({ message, data }, { call, put }) {
            try {
                const result = yield call(r2323ActivityContactService.getList, { activityId: data.activityId });
                if (result && result.length > 0) {
                    const bmzxDatas = result.filter((f) => f.customType === ContactTypeEnum.bmzx);
                    const hyhzDatas = result.filter((f) => f.customType === ContactTypeEnum.hyhz);
                    const zzhzDatas = result.filter((f) => f.customType === ContactTypeEnum.zzhz);
                    const mthzDatas = result.filter((f) => f.customType === ContactTypeEnum.mthz);
                    yield put({ type: "input", data: { bmzxDatas, hyhzDatas, zzhzDatas, mthzDatas } });
                }
                yield put({ type: "input", data: { contacts: result } });
            } catch (e) {
                yield call(message!.error, "getContacts" + e.errmsg);
            }
        },

        // 获取论坛议程区间
        *getDateRange({ message, data }, { call, put }) {
            try {
                const result = yield call(r2323ActivityService.getDateRange, data.activityId);
                let dateRange: any = [];
                if (result) {
                    for (let i = 0; i < result.length; i++) {
                        const result1 = yield call(activityService.getPaged, {
                            pageIndex: 1,
                            pageSize: 20,
                            isValid: true,
                            status: 3,
                            customTypes: [1, 2],
                            languageType: state.languageType,
                            parkId: getCurrentParkId(),
                            activityStartTime: formatDate(result[i], "yyyy-MM-dd"),
                            activityEndTime: formatDate(result[i], "yyyy-MM-dd 23:59:59"),
                        });

                        dateRange.push({
                            day: result[i],
                            dayFormat: moment(result[i]).format("MM/DD ddd"),
                            items: result1 && result1.items,
                        });
                    }
                }

                yield put({ type: "input", data: { dateRange: dateRange } });
            } catch (e) {
                yield call(message!.error, "getDateRange" + e.errmsg);
            }
        },

        // 获取分类议程
        *getActivityTypeRange({ message }, { call, put }) {
            try {
                const result = yield call(tagService.getTagByTagClass, {
                    tagClass: FORUM_TYPE_TAG_CODE,
                    parkId: getCurrentParkId(),
                });

                let activityTypeRange: any = [];
                if (result) {
                    for (let i = 0; i < result.length; i++) {
                        const result1 = yield call(activityService.getPaged, {
                            pageIndex: 1,
                            pageSize: 12,
                            isValid: true,
                            status: 3,
                            customTypes: [1, 2],
                            languageType: state.languageType,
                            parkId: getCurrentParkId(),
                            activityTypeValue: result[i]?.tagValue,
                            orderBy: "startTime asc,r2323AddressValue asc",
                        });

                        activityTypeRange.push({
                            tag: result[i],
                            tagName: result[i]?.tagName,
                            items: result1 && result1.items,
                        });
                    }
                }

                yield put({ type: "input", data: { activityTypeRange: activityTypeRange } });
            } catch (e) {
                yield call(message!.error, "getActivityTypeRange" + e.errmsg);
            }
        },

        /**
         * 获取论坛议程列表
         */
        *getActivityList({ message, data }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.home]),
                    activityModeValue = state.activityModeValue,
                    activityTypeValue = state.activityTypeValue,
                    holdday = state.holdday,
                    activityKey = state.activityKey;
                let activityStartTime: any;
                let activityEndTime: any;
                if (holdday) {
                    activityStartTime = holdday;
                    activityEndTime = holdday + " 23:59:59";
                }
                if (data.holdday) {
                    activityStartTime = holdday;
                    activityEndTime = holdday + " 23:59:59";
                }
                const result = yield call(activityService.getPaged, {
                    pageIndex: 1,
                    pageSize: 20,
                    isValid: true,
                    status: 3,
                    customTypes: [1, 2],
                    languageType: state.languageType,
                    // orderBy: "inputTime desc",
                    parkId: getCurrentParkId(),
                    activityModeValue,
                    activityTypeValue,
                    activityStartTime,
                    activityEndTime,
                    key: activityKey,
                    year: state?.r2323Year || new Date().getFullYear(),
                    ...data,
                });

                yield put({ type: "input", data: { agendaList: result.items } });
            } catch (e) {
                yield call(message!.error, "getActivityList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取论坛分类、方向
         */
        *getTags({ message }, { call, put }) {
            try {
                const tags = yield call(tagService.getTagByTagClasses, {
                    tagClass: [FORUM_TYPE_TAG_CODE, FORUM_DIRE_TAG_CODE],
                    parkId: getCurrentParkId(),
                });
                if (tags[FORUM_DIRE_TAG_CODE]) {
                    let tempArr: any = [];
                    tags[FORUM_DIRE_TAG_CODE].map((t) =>
                        tempArr.push({
                            label: t.tagName,
                            value: t.tagValue,
                            ...t,
                        })
                    );
                    tempArr.unshift({ label: "全部方向", value: "" });
                    tags[FORUM_DIRE_TAG_CODE] = tempArr;
                }

                yield put({ type: "input", data: { tags } });
            } catch (e) {
                yield call(message!.error, "getServiceCatalogue：" + e.errmsg);
            }
        },

        /**
         * 获取往届回顾
         */
        *getPreviousList({ message, data }, { call, put }) {
            try {
                const result = yield call(articleService.getPaged, {
                    pageIndex: 1,
                    pageSize: 1,
                    languageType: state.languageType,
                    isPublish: true,
                    catalogueCode: "WJHG",
                    orderBy: "id desc",
                    ...data,
                });

                yield put({ type: "input", data: { previousList: result.items } });
            } catch (e) {
                yield call(message!.error, "getPreviousList" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        // 判断是否报名
        *IsApply({ message, callback, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                let submitResult = yield call(r2323ActivityApplyService.getIsApply, data);
                if (callback) yield call(callback!, submitResult);
            } catch (e) {
                console.log("error", e.errmsg);
                yield call(message!.error, "IsApply：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        // 判断公告是否开放
        *getNoticeTags({ message, callback }, { call, put }) {
            try {
                const tags = yield call(tagService.getTagByTagClass, { tagClass: "ACTIVITY/GONGGXX" });
                yield put({ type: "input", data: { noticeTag: tags } });
                if (callback) yield call(callback!, tags);
            } catch (e) {
                yield call(message!.error, "getNoticeTags：" + e.errmsg);
            }
        },

        // 获取人脉圈
        *getCircle({ message, callback }, { call, put }) {
            try {
                const result = yield call(memberCircleService.getPaged, { orderBy: "id desc", pageSize: 10 });
                yield put({ type: "input", data: { circleTop: result } });
                if (callback) yield call(callback!, result);
            } catch (e) {
                yield call(message!.error, "getCircle：" + e.errmsg);
            }
        },

        // 获取洽谈邀请
        *getNegotiate({ data, message, callback }, { call, put }) {
            try {
                const result = yield call(talksService.getPaged, { orderBy: "id desc", isValid: true, status: 2, year: OTime.thisYear, pageIndex: 1, pageSize: 2, ...data });
                yield put({ type: "input", data: { negotiateList: result && result.items, negotiateData: result } });
                if (callback) yield call(callback!, result);
            } catch (e) {
                yield call(message!.error, "getNegotiate：" + e.errmsg);
            }
        },

    };
}

app.model(homeModel);
